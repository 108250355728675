import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import firebase from 'firebase/app';
import 'firebase/auth';
import { FIREBASE_CONFIG } from 'src/environments/environment';
import { AltomateService } from './altomate.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public appPages = [
    { title: 'Create A Draft', url: '/folder/inbox', icon: 'mail', type: 'tab' },
    { title: 'Pdf List', url: '/template-list', icon: 'paper-plane', type: 'tab' },
    { title: 'Admin List', url: '/admin-list', icon: 'person', type: 'tab' },
    { title: 'Notification', url: '/notification', icon: 'notifications', type: 'tab' },
    { title: 'Promo Code', url: '/promocode-list', icon: 'barcode', type: 'tab' }
  ];

  ws

  setInterval2;

  public labels = [];
  constructor(
    private nav: NavController,
    private router: Router,
    private altomateService: AltomateService
  ) {
    firebase.initializeApp(FIREBASE_CONFIG);
  }




  signOut() {
    localStorage.removeItem('adminDetails')
    this.nav.navigateRoot('login')
  }

  hideTab() {

    const tabs = ['/pdf-document', '/login']

    if (tabs.includes(this.router.url.split('?')[0])) {
      return true
    }

    return false
  }

  getEmail() {
    return this.altomateService.getEmail()
  }
}


